import React from 'react'
import { isServer } from '../helpers/isServer'
import { cartStore } from './cartStore'
import { storeLocatorStore } from './storeLocatorStore'
import { sideMenuStore } from './sideMenuStore'
import { headerLinkStore } from './headerLinkStore'
import { filterStore } from './filterStore'
import { productsDataStore } from './productsDataStore'
import { compareStore } from './compareStore'

let clientSideStores

export function getStores(data) {
  if (isServer) {
    const serverStores = {
      cartStore: cartStore,
      storeLocatorStore: storeLocatorStore,
      sideMenuStore: sideMenuStore,
      headerLinkStore: headerLinkStore,
      filterStore: filterStore,
      productPageStore: productsDataStore,
      compareStore: compareStore,
    }
    if (data?.product) {
      productsDataStore.setProductPageProduct(data?.product)
    }
    return serverStores
  }
  if (!clientSideStores) {
    clientSideStores = {
      cartStore: cartStore,
      storeLocatorStore: storeLocatorStore,
      sideMenuStore: sideMenuStore,
      headerLinkStore: headerLinkStore,
      filterStore: filterStore,
      productPageStore: productsDataStore,
      compareStore: compareStore,
    }
    if (data?.product) {
      // productsDataStore.setProduct({
      //   ...data?.product,
      //   price: null,
      //   relatedProducts: data?.product?.relatedProducts?.map((rp) => {
      //     return { ...rp, price: null }
      //   }),
      // })
    }
    cartStore.initData(data?.settingsData)
  }

  return clientSideStores
}

const StoreContext = React.createContext()

export function StoreProvider(props) {
  return (
    <StoreContext.Provider value={props.value}>
      {props.children}
    </StoreContext.Provider>
  )
}

export function useMobxStores() {
  return React.useContext(StoreContext)
}
