import { captureException } from './captureException'

export const ERROR_CODES = {
  DEFAULT: 0,
  ADD_TO_CART: 1,
  SET_COUNTRY_AND_LANGUAGE: 2,
  FETCH_CS_OBJECT: 3,
  SET_QUANTITY: 4,
  REMOVE_FROM_CART: 5,
  SUBSCRIBE_TO_NEWS_LETTER: 6,
  ADD_VOUCHER: 7,
  REMOVE_VOUCHER: 8,
  START_CHECKOUT: 9,
  PAYMENT_FIELDS: 10,
  GET_RECEIPT: 11,
  GET_PAYMENT_RESULT: 12,
  FETCH_PRODUCT: 13,
  FETCH_PRODUCTS: 14,
  SIGN_UP: 15,
  LOGOUT: 16,
  LOGIN: 17,
  RESET_PASSWORD: 18,
  UPDATE_USER_DETAILS: 19,
  GET_CUSTOMER_ORDERS: 20,
  GET_CUSTOMER_SUBSCRIPTIONS: 21,
  CHANGE_SUB_STATUS: 22,
  FETCH_ALL_PRODUCT: 23,
  UPDATE_USER_EMAIL: 24,
}
export const logError = ({ error, errorCode }) => {
  console.log('ErrorCode:' + errorCode)
  captureException(Error('ErrorCode:' + errorCode, { cause: error }))
}
