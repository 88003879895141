import { store } from 'react-notifications-component'
import 'animate.css/animate.min.css'

const showAlert = ({
  title = '',
  message = '',
  insert = 'top',
  container = 'top-right',
  duration = 6000,
  onScreen = false,
  type = 'default',
}) => {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: insert,
    container: container,
    animationIn: ['animate__animated', 'animate__fadeInRight'],
    animationOut: ['animate__animated', 'animate__fadeOutRight'],
    dismiss: {
      duration: duration,
      onScreen: onScreen,
    },
  })
}

export const showErrorAlert = ({ title = '', message = '', errorCode = 0 }) => {
  showAlert({
    title: title + ' (error code : ' + errorCode + ')',
    message: message,
    type: 'danger',
  })
}
export const showWarningAlert = ({ title = '', message = '' }) => {
  showAlert({
    title: title,
    message: message,
    type: 'warning',
  })
}
