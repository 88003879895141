import '../styles/globals.css'
import '../styles/theme.css'
import '../../public/custom_fonts.css'

import dynamic from 'next/dynamic'
import App from 'next/app'
import { useContext, useEffect } from 'react'
import { enableStaticRendering } from 'mobx-react'
const ReactNotification = dynamic(() => import('react-notifications-component'))
import('react-notifications-component/dist/theme.css')
import { getStores, StoreProvider } from '../store/stores'
import { CartStoreContext } from '../store/cartStore'
import { useRouter } from 'next/router'
import { parseCookies, setCookie } from 'nookies'

const isServer = typeof window === 'undefined'
// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(isServer)

class CustomApp extends App {
  static async getServerSideProps(appContext) {
    // On server-side, this runs once and creates new stores
    // On client-side, this always reuses existing stores
    const mobxStores = getStores()

    // Make stores available to page's `getInitialProps`
    appContext.ctx.mobxStores = mobxStores

    // Call "super" to run page's `getInitialProps`
    const appProps = await App.getInitialProps(appContext)

    // Gather serialization-friendly data from stores
    const initialData = {}

    // Send it to `render`
    return {
      ...appProps,
      initialData,
    }
  }

  render() {
    const { Component, pageProps } = this.props

    // During SSR, this will create new store instances so having `initialData` is crucial.
    // During the client-side hydration, same applies.
    // From then on, calls to `getStores()` return existing instances.
    const stores = getStores(pageProps?.data)

    return (
      <StoreProvider value={stores}>
        <CentraCheckoutWrapper {...pageProps}>
          <>
            <ReactNotification />
            <Component {...pageProps} />
          </>
        </CentraCheckoutWrapper>
      </StoreProvider>
    )
  }
}

const CentraCheckoutWrapper = ({ children, data }) => {
  const {
    centraCheckoutCallback,
    fetchCSObject,
    setLanguage,
    setLanguageDontMatchText,
    setCountryAndLanguage,
  } = useContext(CartStoreContext)
  const router = useRouter()

  const checkIfReroute = async (CSObject) => {
    const locale = data?.locale
    const locales = data?.locales
    const urlParams = new URLSearchParams(window?.location?.search)
    const country = urlParams.get('country')
    const { asPath } = router

    if (country) {
      setCountryAndLanguage({
        country: country,
        language: locale,
      })
      // if server and client don't match language => change to right lang
    } else if (locale !== CSObject?.location?.language?.language) {
      // check if locales exist
      if (
        locales?.length > 0 &&
        locales?.find((t) => t === CSObject?.location?.language?.language)
      ) {
        // if path is home
        if (asPath === '/') {
          // const cookies = parseCookies()
          // if (!cookies?.ventanas_language_dont_match) {
          setLanguageDontMatchText(
            data?.settingsData.language_dont_match_title ||
              "It seems that the language on our site doesn't match the country you are browsing from. If you'd like to change it, you can do so here.",
          )
          // setCookie(null, 'ventanas_language_dont_match', true, {
          //   maxAge: 365 * 24 * 60 * 60, // one year
          //   path: '/',
          // })
          // }
        } else if (!country) {
          // IF not on home juste update CSOBJECT lang
          if (data?.settingsData?.one_one_market !== 'yes') {
            setLanguage({ language: locale })
          } else {
            const countries = CSObject?.countries?.filter(
              (c) => c.language === locale,
            )

            setCountryAndLanguage({
              country: countries[0].country,
              language: countries[0].language,
            })
          }
        }
      }
    }
  }

  useEffect(() => {
    const fetch = async () => {
      const CSObject = await fetchCSObject()
      checkIfReroute(CSObject)
    }
    fetch()
    function handleCallback(o) {
      centraCheckoutCallback(o)
    }
    document.addEventListener('centra_checkout_callback', handleCallback)
    return function cleanupListener() {
      document.removeEventListener('centra_checkout_callback', handleCallback)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return children
}

export default CustomApp

// Need this for tailwind so they dont remove the css
const rounded =
  'rounded rounded-md rounded-lg rounded-xl rounded-2xl rounded-3xl'
