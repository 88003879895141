import React from 'react'
import { makeAutoObservable } from 'mobx'

class HeaderLinkStore {
  constructor() {
    this.link = null
    makeAutoObservable(this)
  }
  setLink = (s) => {
    this.link = s
  }
}

export const headerLinkStore = (() => {
  return new HeaderLinkStore()
})()

export const HeaderLinkStoreContext = React.createContext(headerLinkStore)
