import { isServer } from '../../helpers/isServer'

export const fbPurchase = async ({ orderData }) => {
  // Common data
  const fbProducts = orderData?.items.map((itemData) => {
    return {
      id: itemData.id,
      quantity: itemData.quantity ?? 1,
    }
  })
  const fbPixelData = {
    content_type: 'product',
    contents: fbProducts,
    value: orderData.total,
    currency: orderData.currency,
  }
  const pixelFound = fbqTrack({
    event: 'Purchase',
    data: fbPixelData,
    deduplication: { eventID: orderData.orderId },
  }) // Trigger event

  // Facebook Conversion API
  const fbApiData = {
    event_name: 'Purchase',
    products: fbProducts,
    email: orderData.email,
    phoneNumber: orderData.phoneNumber,
    firstName: orderData.firstName,
    lastName: orderData.lastName,
    city: orderData.city,
    state: orderData.state,
    zipCode: orderData.zipCode,
    country: orderData.country,
    locale: orderData.locale,
    value: orderData.total,
    currency: orderData.currency,
    order_id: orderData.orderId,
  }

  if (process.env.NEXT_PUBLIC_DEBUG_TRACKING === 'true') {
    console.log('Facebook API preview', fbApiData)
  }

  if (!pixelFound) return false

  const res = await fetch('/api/facebook', {
    method: 'POST',
    headers: {},
    body: JSON.stringify(fbApiData),
  })
}

export const fbViewContent = ({ itemData }) => {
  fbqTrack({
    event: 'ViewContent',
    data: {
      content_ids: [itemData.id],
      content_category: itemData.categoryName.join(' > '),
      content_name: itemData.name,
      content_type: 'product',
      contents: [
        {
          id: itemData.id,
          quantity: 1,
        },
      ],
      currency: itemData.currency,
      value: itemData.price,
    },
  })
}

export const fbBeginCheckout = ({ orderData }) => {
  fbqTrack({
    event: 'InitiateCheckout',
    data: {
      content_category: '',
      content_ids: orderData.items.map((itemData) => {
        return itemData.id
      }),
      contents: orderData.items.map((itemData) => {
        return {
          id: itemData.id,
          quantity: itemData.quantity,
        }
      }),
      currency: orderData.currency,
      num_items: orderData.items.length,
      value: orderData.total,
    },
  })
}

export const fbAddToCart = ({ itemData }) => {
  fbqTrack({
    event: 'AddToCart',
    data: {
      content_name: itemData.name,
      content_category: itemData.categoryName.join(' > '),
      content_ids: itemData.id,
      content_type: 'product',
      value: itemData.price,
      currency: itemData.currency,
    },
  })
}

const fbqTrack = ({ event, data, deduplication }) => {
  if (isServer) {
    return false
  }

  // Preview for debug
  if (process.env.NEXT_PUBLIC_DEBUG_TRACKING === 'true') {
    console.log('fb event preview', event, data, deduplication)
    //return true
  }

  // Fail safe for tracking consent
  // const cookies = parseCookies()
  // if (!cookies?.cookieConsentCategories?.includes('marketing')) {
  //   return false
  // }

  if (!window.fbq) {
    console.log('no meta pixel found')
    return false
  }

  if (deduplication) {
    fbq('track', event, data, deduplication)
  } else {
    fbq('track', event, data)
  }

  return true
}
