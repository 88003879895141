import { isServer } from '../../helpers/isServer'
import { getYotpoProductId } from '../../helpers/getProductTrackingId'

export const yotpoTriggerPurchase = async ({ orderData }) => {
  if (isServer) {
    return false
  }
  // Get date
  const date = new Date()
  const order_date = date.toISOString().slice(0, 19) + 'Z'

  // Get products
  let products = []
  orderData.items.map((itemData) => {
    products.push({
      external_product_id: getYotpoProductId({
        product: itemData,
      }),
      quantity: itemData.quantity ?? 1,
      total_price: itemData.totalPrice ?? 0,
    })
  })

  // Get customer
  const customer = {
    external_id: orderData.email,
    first_name: orderData.firstName,
    last_name: orderData.lastName,
    email: orderData.email,
    phone_number: orderData.phoneNumber,
  }

  // Create data obj
  const data = {
    locale: orderData.locale,
    order_date: order_date,
    order_id: orderData.orderId,
    products: products,
    customer: customer,
  }

  if (process.env.NEXT_PUBLIC_DEBUG_TRACKING === 'true')
    console.log('Yotpo tracking preview', data)

  await fetch('/api/yotpo', {
    method: 'POST',
    body: JSON.stringify(data),
  })
}
