import React from 'react'
import { makeAutoObservable } from 'mobx'

class ProductsDataStore {
  constructor() {
    this.productPageProduct = null
    this.productPageSize = { x: 0, y: 0 }
    this.standAloneProducts = {}
    this.standAloneProductSize = {}
    makeAutoObservable(this)
  }

  getItem = (standAloneId) => {
    if (standAloneId) {
      if (this.standAloneProducts?.[standAloneId].items?.length === 1) {
        return this.standAloneProducts?.[standAloneId]?.items[0]
      }

      const x = this.standAloneProductSize?.[standAloneId]?.x ?? 0
      const y = this.standAloneProductSize?.[standAloneId]?.y ?? 0
      return this.standAloneProducts?.[standAloneId]?.items?.find(
        (item) => item.itemTableX === x && item.itemTableY === y,
      )
    } else {
      if (this.productPageProduct?.items?.length === 1) {
        return this.productPageProduct?.items[0]
      }

      const x = this.productPageSize?.x ?? 0
      const y = this.productPageSize?.y ?? 0
      return this.productPageProduct?.items?.find(
        (item) => item.itemTableX === x && item.itemTableY === y,
      )
    }
  }

  setStandAloneSize = (standAloneId, size) => {
    this.standAloneProductSize[standAloneId] = size
  }

  setSize = (size) => {
    this.productPageSize = size
  }

  setProductPageProduct = (product) => {
    this.productPageProduct = product
  }

  setStandAloneProduct = (product) => {
    this.standAloneProducts[product?.product] = product
  }
}

export const productsDataStore = (() => {
  return new ProductsDataStore()
})()

export const ProductsDataStoreContext = React.createContext(productsDataStore)
