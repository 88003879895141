export const getProductTrackingId = ({ country, item }) => {
  const variables = {
    '%store_id%': process.env.NEXT_PUBLIC_CENTRA_STORE_ID,
    '%size_id%': getSizeIdFromSelectionItem(item) ?? '',
    '%country_code%': country ?? '',
    '%product_id%': item?.product?.centraProduct ?? '',
    '%variant_id%': item?.product?.centraVariant ?? '',
    '%product_sku%': item?.product?.productSku ?? '',
    '%sku%': item?.product?.sku ?? '',
  }

  let idString = process.env.productTrackingIdFormat
  //idString = '%store_id%/%country_code%-p%product_id%-v%variant_id%-s%size_id%'
  for (const variable in variables) {
    idString = idString.replace(variable, variables[variable])
  }

  return idString
}

export const getYotpoProductId = ({ product }) => {
  const productId = product?.sku

  return `${productId}`
}

const getSizeIdFromSelectionItem = (item) => {
  // const productItem = item.product.items.find((i) => {
  //   return i.name === item.size
  // })
  if (item?.item) {
    const [product, sizeId] = item?.item?.split('-')
    if (sizeId) {
      return sizeId
    } else {
      return ''
    }
  }
  return ''
}
