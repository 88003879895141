import React from 'react'
import {
  triggerAddToCart,
  triggerRemoveFromCart,
  triggerPurchase,
} from '../lib/analytics/events'

import { makeAutoObservable, toJS } from 'mobx'
import {
  addToSelection,
  startPayment,
  changeCountry,
  removeFromSelection,
  getCentraSessionObject,
  setQuantityForSelection,
  addVoucherToSelection,
  removeVoucherToSelection,
  newLetterSubscription,
  paymentFields,
  centraGetReceipt,
  paymentResult,
  getProduct,
  getProducts,
  getCategories,
  getCurrentProductFromUri,
  getCurrentCategoryFromUri,
  getProductsFromCategoryUri,
  getProductFromUri,
  getProductWithCentraProductId,
  changeLanguage,
  registerUser,
  logoutUser,
  loginUser,
  resetPassword,
  sendResetPasswordEmail,
  updateAddress,
  getOrders,
  updateCustomer,
  getSubscriptions,
  updateItemsSubscription,
  setStatusOnSubscription,
  updateCustomerEmail,
} from '../lib/centra/centra'
import { showErrorAlert, showWarningAlert } from '../helpers/alert'
import { ERROR_CODES, logError } from '../helpers/error'
import { getPath } from '../helpers/getPath'
import { getLocalizedSize } from '../helpers/getMappedSizeName'
import { isObjectEmpty } from '../helpers/isObjectEmpty'

class CartStore {
  constructor() {
    //centra session object 💪
    this.CSObject = null
    this.animation = false
    this.showCart = false
    this.errorMessages = {
      defaultTitle: '',
      defaultMessage: '',
      notAvailableTitle: '',
      notAvailableMessage: '',
      voucherNotFoundTitle: '',
      voucherNotFoundMessage: '',
      loginFailedTitle: '',
      loginFailedMessage: '',
      signupFailedTitle: '',
      signupFailedMessage: '',
      changeEmailFailedTitle: '',
      changeEmailFailedMessage: '',
    }
    this.unavailable = null
    this.checkout = null
    this.languageDontMatchText = ''
    this.centraCheckoutScriptLoading = false
    makeAutoObservable(this)
  }

  initData(data) {
    this.errorMessages.defaultTitle = data?.em_default_error_title
    this.errorMessages.defaultMessage = data?.em_default_error_message
    this.errorMessages.notAvailableTitle = data?.em_product_not_available_title
    this.errorMessages.notAvailableMessage =
      data?.em_product_not_available_message
    this.errorMessages.voucherNotFoundTitle = data?.em_voucher_not_found_title
    this.errorMessages.voucherNotFoundMessage =
      data?.em_voucher_not_found_message

    // new title and messages

    // Login
    this.errorMessages.loginFailedTitle =
      data?.em_login_failed_title ?? 'Login failed'
    this.errorMessages.loginFailedMessage =
      data?.em_login_failed_message ?? 'Email or password is incorrect'

    // Signup
    this.errorMessages.signupFailedTitle =
      data?.em_signup_failed_title ?? 'Signup failed'
    this.errorMessages.signupFailedMessage =
      data?.em_signup_failed_message ??
      'Could not create account. The email is already in use'

    // Change email
    this.errorMessages.changeEmailFailedTitle =
      data?.em_change_email_failed_title ?? 'Change email failed'
    this.errorMessages.changeEmailFailedMessage =
      data?.em_change_email_failed_message ??
      'Could not change email. The email is already in use'
  }

  setAnimation = (a) => {
    this.animation = a
  }

  setShowCart = (c) => {
    this.showCart = c
  }

  centraCheckoutSuspend = () => {
    if (typeof CentraCheckout !== 'undefined') {
      CentraCheckout.suspend()
    }
  }
  centraCheckoutResume = () => {
    if (typeof CentraCheckout !== 'undefined') {
      CentraCheckout.resume()
    }
  }

  checkCentraCheckoutScript = () => {
    if (this.CSObject?.selection?.centraCheckoutScript) {
      const id = 'centracheckoutscript'
      const createScript = () => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.id = id
        script.innerHTML = this.CSObject?.selection?.centraCheckoutScript
        document.body.appendChild(script)
      }

      if (document.getElementById(id)) {
        const element = document.getElementById(id)
        element.remove()
      }
      createScript()
    }
  }

  setCSObject = (o) => {
    this.CSObject = o
  }
  setCheckout = (c) => {
    this.checkout = c
  }

  setCentraCheckoutScriptLoading = (value) => {
    this.centraCheckoutScriptLoading = value
  }

  setLanguageDontMatchText = (value) => {
    this.languageDontMatchText = value
  }

  centraCheckoutCallback = async (origdata) => {
    try {
      this.centraCheckoutSuspend()
      this.setCentraCheckoutScriptLoading(true)
      const response = await paymentFields({ data: origdata.detail })
      this.setCSObject(response)
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.PAYMENT_FIELDS,
      })
      logError({ error: e, errorCode: ERROR_CODES.PAYMENT_FIELDS })
    } finally {
      this.setCentraCheckoutScriptLoading(false)
      this.centraCheckoutResume()
    }
  }

  fetchCSObject = async () => {
    try {
      const response = await getCentraSessionObject()
      this.setCSObject(response)
      return response
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.FETCH_CS_OBJECT,
      })
      logError({ error: e, errorCode: ERROR_CODES.FETCH_CS_OBJECT })
    }
  }

  fetchProductFromUri = async ({ uri }) => {
    try {
      const p = await getProductFromUri({ uri })
      return p
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.FETCH_PRODUCT,
      })
      logError({ error: e, errorCode: ERROR_CODES.FETCH_PRODUCT })
    }
  }

  fetchProduct = async (id) => {
    try {
      const p = await getProduct({ id })
      return p
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.FETCH_PRODUCT,
      })
      logError({ error: e, errorCode: ERROR_CODES.FETCH_PRODUCT })
    }
  }

  fetchProductSilent = async (id) => {
    try {
      const p = await getProduct({ id })
      return p
    } catch (e) {
      logError({ error: e, errorCode: ERROR_CODES.FETCH_PRODUCT })
    }
  }

  fetchProductWithCentraProductId = async (id) => {
    try {
      const p = await getProductWithCentraProductId({ id })
      return p
    } catch (e) {
      logError({ error: e, errorCode: ERROR_CODES.FETCH_PRODUCT })
    }
  }

  fetchAllProducts = async () => {
    try {
      const p = await getProducts({ limit: 0 })
      return p
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.FETCH_ALL_PRODUCT,
      })
      logError({ error: e, errorCode: ERROR_CODES.FETCH_ALL_PRODUCT })
    }
  }

  fetchProducts = async ({
    categories,
    skip,
    search,
    market,
    limit,
    pricelist,
    filters,
    sortOrder,
  }) => {
    try {
      const fixedFilters = filters?.reduce((acc, currentValue) => {
        acc[currentValue.field] = [...currentValue.values]
        return acc
      }, {})

      const productsData = await getProducts({
        categories,
        skip,
        search,
        market,
        limit,
        pricelist,
        // todo inte super nöjd med detta?? Funkar ju nice dock
        filters: isObjectEmpty(fixedFilters) ? null : fixedFilters,
        sortOrder: isObjectEmpty(sortOrder) ? null : sortOrder,
      })
      return productsData
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.FETCH_PRODUCTS,
      })
      logError({ error: e, errorCode: ERROR_CODES.FETCH_PRODUCTS })
    }
  }

  fetchProductsFromUri = async ({ uri }) => {
    try {
      const productsData = await getProductsFromCategoryUri({
        uri: uri,
      })
      return productsData
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.FETCH_PRODUCTS,
      })
      logError({ error: e, errorCode: ERROR_CODES.FETCH_PRODUCTS })
    }
  }

  getCurrentRoute = async ({ path, locale }) => {
    if (path.includes('/product/')) {
      path = getPath(path)
      const product = await getCurrentProductFromUri({
        uri: path,
        locale: locale,
      })
      if (product) {
        return { id: product.product, isProduct: true }
      }
    } else if (path.includes('/category/')) {
      path = getPath(path)
      const currentCategory = await getCurrentCategoryFromUri({
        uri: path,
        locale: locale,
      })
      if (currentCategory) {
        return { id: currentCategory.category, isProduct: false }
      }
    }
  }
  getRouteQuery = async ({ current, language }) => {
    if (current.isProduct) {
      const product = await getProduct({ id: current.id, locale: language })
      return {
        query: { product: [product.product.uri] },
        path: '/product/' + product.product.uri,
      }
    } else {
      const newCategories = await getCategories({
        locale: language,
        dontSaveCentraToken: true,
      })
      const newCategory = newCategories.find(
        (category) => category.category === current.id,
      )
      return {
        query: { category: [newCategory.uri] },
        path: '/category/' + newCategory.uri,
      }
    }
  }

  setLanguage = async ({ language }) => {
    try {
      this.centraCheckoutSuspend()
      const response = await changeLanguage({ locale: language })
      if (!response.errors) {
        this.setCSObject(response)
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.centraCheckoutResume()
    }
  }
  setCountryAndLanguage = async ({ country, language }) => {
    try {
      this.centraCheckoutSuspend()
      let response = await changeCountry({
        country: country,
        locale: language,
      })
      if (!response.errors) {
        this.setCSObject(response)
      }
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.SET_COUNTRY_AND_LANGUAGE,
      })
      logError({
        error: e,
        errorCode: ERROR_CODES.SET_COUNTRY_AND_LANGUAGE,
      })
    } finally {
      this.centraCheckoutResume()
    }
  }

  addToCart = async (itemData, product) => {
    try {
      this.centraCheckoutSuspend()
      const localizedSize = getLocalizedSize({
        sizeName: itemData?.name,
        defaultLocalizedChart: product?.defaultLocalizedChart,
        tableMappings: product?.tableMappings,
      })
      let body = {}
      if (localizedSize && localizedSize?.length > 0) {
        body = {
          localizedProdSize: {
            localizationDefinitionName: product.defaultLocalizedChart,
            localizedSize: localizedSize,
          },
        }
      }

      const response = await addToSelection({
        id: itemData.item,
        body: {
          ...body,
        },
      })
      this.setCSObject(response)

      // Tracking
      triggerAddToCart({
        item: { ...itemData, product: product },
        CSObject: this.CSObject,
      })

      return response
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.ADD_TO_CART,
      })
      logError({ error: e, errorCode: ERROR_CODES.ADD_TO_CART })
    } finally {
      this.centraCheckoutResume()
    }
  }

  setQuantity = async ({ item, newQuantity }) => {
    try {
      this.centraCheckoutSuspend()
      const response = await setQuantityForSelection({
        lineId: item.line,
        quantity: newQuantity,
      })
      this.setCSObject(response)

      if (newQuantity === 0) {
        // Tracking
        triggerRemoveFromCart({
          CSObject: this.CSObject,
          item,
        })
      }
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.SET_QUANTITY,
      })
      logError({ error: e, errorCode: ERROR_CODES.SET_QUANTITY })
    } finally {
      this.centraCheckoutResume()
    }
  }

  removeFromCart = async ({ item }) => {
    try {
      this.centraCheckoutSuspend()
      const response = await removeFromSelection({ id: item.line })
      this.setCSObject(response)

      // Tracking
      triggerRemoveFromCart({
        CSObject: this.CSObject,
        item,
      })
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.REMOVE_FROM_CART,
      })
      logError({ error: e, errorCode: ERROR_CODES.REMOVE_FROM_CART })
    } finally {
      this.centraCheckoutResume()
    }
  }

  subscribeToNewLetter = async ({ email, product, item }) => {
    try {
      const language = this.CSObject?.location?.language?.language
      const country = this.CSObject?.location?.country
      await newLetterSubscription({ email, country, language, product, item })
      return true
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.SUBSCRIBE_TO_NEWS_LETTER,
      })
      logError({
        error: e,
        errorCode: ERROR_CODES.SUBSCRIBE_TO_NEWS_LETTER,
      })
    } finally {
    }
  }

  addVoucher = async (code) => {
    try {
      this.centraCheckoutSuspend()
      const response = await addVoucherToSelection({ code })
      this.setCSObject(response)
    } catch (e) {
      if (e.status === 404) {
        showWarningAlert({
          title: this.errorMessages.voucherNotFoundTitle,
          message: this.errorMessages.voucherNotFoundMessage,
        })
      } else {
        showErrorAlert({
          title: this.errorMessages.defaultTitle,
          message: this.errorMessages.defaultMessage,
          errorCode: ERROR_CODES.ADD_VOUCHER,
        })
      }
      logError({ error: e, errorCode: ERROR_CODES.ADD_VOUCHER })
    } finally {
      this.centraCheckoutResume()
    }
  }

  removeVoucher = async (code) => {
    try {
      this.centraCheckoutSuspend()
      const response = await removeVoucherToSelection({ code })
      this.setCSObject(response)
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.REMOVE_VOUCHER,
      })
      logError({ error: e, errorCode: ERROR_CODES.REMOVE_VOUCHER })
    } finally {
      this.centraCheckoutResume()
    }
  }

  startCheckout = async () => {
    try {
      this.setCheckout(null)
      const klarna = await startPayment({
        country: this.CSObject?.location.country,
        language: this.CSObject?.location.language.language,
        paymentMethod: this.CSObject?.paymentMethods[0]?.paymentMethod,
        shippingMethod: this.CSObject?.shippingMethods[0]?.shippingMethod,
        CSObject: this.CSObject,
      })
      this.setCheckout(klarna.formHtml)
      return true
    } catch (e) {
      if (e.unavailable) {
        // TODO SET IN CATCH REAALY? :O
        this.setCSObject(e)
        showWarningAlert({
          title: this.errorMessages.notAvailableTitle,
          message: this.errorMessages.notAvailableMessage,
        })
      } else {
        console.log(e)
        showErrorAlert({
          title: this.errorMessages.defaultTitle,
          message: this.errorMessages.defaultMessage,
          errorCode: ERROR_CODES.START_CHECKOUT,
        })
        logError({ error: e, errorCode: ERROR_CODES.START_CHECKOUT })
      }
    }
    return false
  }

  getReceipt = async ({ settingsData, token, locale }) => {
    try {
      const receipt = await centraGetReceipt({ token })

      // Trigger tracking event
      triggerPurchase({
        order: receipt?.order,
        CSObject: this.CSObject,
        settingsData: settingsData,
        locale,
      })

      return receipt
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.GET_RECEIPT,
      })

      logError({ error: e, errorCode: ERROR_CODES.GET_RECEIPT })
    }
    return false
  }

  getPaymentResult = async ({ query, token }) => {
    try {
      const receipt = await paymentResult({ query, token })
      return receipt
    } catch (e) {
      if (e.status !== 404 && e.status !== 412) {
        showErrorAlert({
          title: this.errorMessages.defaultTitle,
          message: this.errorMessages.defaultMessage,
          errorCode: ERROR_CODES.GET_PAYMENT_RESULT,
        })
      }
      if (e.status !== 404) {
        logError({ error: e, errorCode: ERROR_CODES.GET_PAYMENT_RESULT })
      }
    }
  }

  addSubscription = async ({ lineId, subscriptionId }) => {
    try {
      this.centraCheckoutSuspend()
      const response = await updateItemsSubscription({
        lineId,
        subscriptionId,
      })
      this.setCSObject(response)
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.SET_QUANTITY,
      })
      logError({ error: e, errorCode: ERROR_CODES.SET_QUANTITY })
    } finally {
      this.centraCheckoutResume()
    }
  }

  changeStatusOnSubscription = async ({ subscriptionId, status }) => {
    try {
      await setStatusOnSubscription({
        subscriptionId,
        status,
      })
      const contracts = this.CSObject?.contracts
      for (const contract of contracts) {
        for (let subscription of contract.subscriptions) {
          if (subscription.subscription === subscriptionId) {
            subscription.status = status
          }
        }
      }
      this.setCSObject({ ...this.CSObject, contracts: contracts })
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.CHANGE_SUB_STATUS,
      })
      logError({ error: e, errorCode: ERROR_CODES.CHANGE_SUB_STATUS })
    }
  }

  // MY page

  signUpUser = async ({ email, password, firstName, lastName }) => {
    try {
      const response = await registerUser({
        email,
        password,
        firstName,
        lastName,
      })
      this.setCSObject(response)
      return response
    } catch (e) {
      if (e.status === 409) {
        showWarningAlert({
          title: this.errorMessages.signupFailedTitle,
          message: this.errorMessages.signupFailedMessage,
        })
      } else {
        showErrorAlert({
          title: this.errorMessages.defaultTitle,
          message: this.errorMessages.defaultMessage,
          errorCode: ERROR_CODES.SIGN_UP,
        })
        logError({ error: e, errorCode: ERROR_CODES.SIGN_UP })
      }
    }
  }

  logout = async () => {
    try {
      const response = await logoutUser()
      this.setCSObject(response)
      return response
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.LOGOUT,
      })
      logError({ error: e, errorCode: ERROR_CODES.LOGOUT })
    }
  }

  login = async ({ email, password }) => {
    try {
      const response = await loginUser({ email, password })
      this.setCSObject(response)
      return response
    } catch (e) {
      if (e.status === 406) {
        showWarningAlert({
          title: this.errorMessages.loginFailedTitle,
          message: this.errorMessages.loginFailedMessage,
        })
      } else {
        showErrorAlert({
          title: this.errorMessages.defaultTitle,
          message: this.errorMessages.defaultMessage,
          errorCode: ERROR_CODES.LOGIN,
        })
        logError({ error: e, errorCode: ERROR_CODES.LOGIN })
      }
    }
  }

  sendResetUserPasswordEmail = async ({ email }) => {
    try {
      const response = await sendResetPasswordEmail({ email })
      this.setCSObject(response)
      return response
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.RESET_PASSWORD,
      })
      logError({ error: e, errorCode: ERROR_CODES.RESET_PASSWORD })
    }
  }

  resetUserPassword = async ({ id, i, password }) => {
    try {
      const response = await resetPassword({ id, i, password })
      this.setCSObject(response)
      return response
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.RESET_PASSWORD,
      })
      logError({ error: e, errorCode: ERROR_CODES.RESET_PASSWORD })
    }
  }
  updateUserDetails = async ({
    firstName,
    lastName,
    address1,
    zipCode,
    city,
    state,
    country,
    phoneNumber,
  }) => {
    try {
      const response = await updateCustomer({
        firstName,
        lastName,
        address1,
        zipCode,
        city,
        state,
        country,
        phoneNumber,
      })
      this.setCSObject(response)
      return response
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.UPDATE_USER_DETAILS,
      })
      logError({ error: e, errorCode: ERROR_CODES.UPDATE_USER_DETAILS })
    }
  }

  getCustomerOrders = async ({ size, from }) => {
    try {
      const response = await getOrders({ size, from })
      this.setCSObject({
        ...this.CSObject,
        orders: response?.orders,
        ordersPaging: response?.ordersPaging,
      })
      return response
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.GET_CUSTOMER_ORDERS,
      })
      logError({ error: e, errorCode: ERROR_CODES.GET_CUSTOMER_ORDERS })
    }
  }
  getCustomerSubscriptions = async () => {
    try {
      const response = await getSubscriptions()
      this.setCSObject({
        ...this.CSObject,
        token: response?.token,
        contracts: response?.contracts,
      })
      return response
    } catch (e) {
      showErrorAlert({
        title: this.errorMessages.defaultTitle,
        message: this.errorMessages.defaultMessage,
        errorCode: ERROR_CODES.GET_CUSTOMER_SUBSCRIPTIONS,
      })
      logError({ error: e, errorCode: ERROR_CODES.GET_CUSTOMER_SUBSCRIPTIONS })
    }
  }
  updateUserEmail = async ({ email }) => {
    try {
      const response = await updateCustomerEmail({ email })
      return response
    } catch (e) {
      if (e?.newEmail === 'already registered') {
        showWarningAlert({
          title: this.errorMessages.changeEmailFailedTitle,
          message: this.errorMessages.changeEmailFailedMessage,
        })
      } else {
        showErrorAlert({
          title: this.errorMessages.defaultTitle,
          message: this.errorMessages.defaultMessage,
          errorCode: ERROR_CODES.UPDATE_USER_EMAIL,
        })
      }
      logError({ error: e, errorCode: ERROR_CODES.UPDATE_USER_EMAIL })
    }
  }
}

export const cartStore = (() => {
  return new CartStore()
})()

export const CartStoreContext = React.createContext(cartStore)
