import { waitForFindify } from '@findify/react-bundle'
import { isServer } from '../../helpers/isServer'

export const findifyUpdateCart = ({ orderData }) => {
  const data = {
    line_items: getProducts({ items: orderData.items }),
  }

  sendEvent({ event: 'update-cart', data })
}

export const findifyPurchase = ({ orderData }) => {
  const data = {
    currency: orderData.currency,
    line_items: getProducts({ items: orderData.items }),
    order_id: orderData.orderId,
    revenue: orderData.total,
  }

  sendEvent({ event: 'purchase', data })
}

export const findifyViewPage = ({ itemData }) => {
  if (itemData) {
    // Only if product page
    const findifyProduct = getProduct({ itemData })
    const data = {
      item_id: findifyProduct.item_id,
      variant_item_id: findifyProduct.variant_item_id,
      force: true,
    }
    sendEvent({ event: 'view-page', data })
  } else if (window.location.href.indexOf('product') === -1) {
    // Default page view event
    sendEvent({ event: 'view-page', data: { force: true } })
  }
}

const sendEvent = async ({ event, data }) => {
  if (isServer) return

  if (process.env.NEXT_PUBLIC_DEBUG_TRACKING === 'true') {
    console.log('findify event preview', event, data)
  }

  const { analytics } = await waitForFindify()
  analytics.sendEvent(event, data)
}

const getProducts = ({ items }) => {
  return items.map((itemData) => {
    return getProduct({ itemData })
  })
}
const getProduct = ({ itemData }) => {
  return {
    item_id: itemData.centraVariant,
    quantity: itemData.quantity ?? 1,
    unit_price: itemData.price ?? 0,
    variant_item_id: itemData.centraVariant,
  }
}
