import React from 'react'
import { makeAutoObservable } from 'mobx'

class StoreLocatorStore {
  constructor() {
    this.selectedMarker = null
    this.hoveredMarker = null
    this.map = null
    this.mapApi = null
    this.userPos = null
    makeAutoObservable(this)
  }

  setSelectedMarker = (marker) => {
    this.selectedMarker = marker
  }

  setHoveredMarker = (marker) => {
    this.hoveredMarker = marker
  }

  setMap = (map) => {
    this.map = map
  }

  setMapApi = (api) => {
    this.mapApi = api
  }

  setPos = (pos) => {
    this.map.setCenter(pos)
  }

  setUserPos = (userPos) => {
    this.userPos = userPos
  }
}

export const storeLocatorStore = (() => {
  return new StoreLocatorStore()
})()

export const StoreLocatorStoreContext = React.createContext(storeLocatorStore)
