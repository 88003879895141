export const getMappedSizeName = ({
  size,
  tableMappings,
  defaultLocalizedChart,
  sizeKey = 'x',
}) => {
  if (!tableMappings || !defaultLocalizedChart) {
    return size
  }

  const mapping = tableMappings[defaultLocalizedChart]
  if (mapping && mapping?.[sizeKey]?.[size]) {
    return mapping?.[sizeKey]?.[size]
  }
  return size
}

export const getMappedSizeNameFromItem = ({ item }) => {
  if (item?.localizedSize?.localizedSize) {
    return item?.localizedSize?.localizedSize
  }

  return item?.size
}

export const getLocalizedSize = ({
  sizeName,
  tableMappings,
  defaultLocalizedChart,
}) => {
  if (tableMappings && defaultLocalizedChart) {
    let localizedSize = ''
    const mapping = tableMappings[defaultLocalizedChart]

    const dividerSymbol = mapping?.dividerSymbol

    // get X siz
    const xName = sizeName?.split(dividerSymbol)?.[0]
    if (xName) {
      localizedSize = localizedSize + mapping?.x?.[xName]
    }

    // get Y size
    const yName = sizeName?.split(dividerSymbol)?.[1]
    if (yName) {
      localizedSize = localizedSize + dividerSymbol + mapping?.y?.[yName]
    }

    return localizedSize
  }
}
