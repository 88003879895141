import React from 'react'
import { makeAutoObservable } from 'mobx'

class SideMenuStore {
  constructor() {
    this.viewIndex = 0
    this.backgroundColor = 'white'
    this.centraCategoryUri = ''
    this.custom = null
    this.showMobileMenu = false
    makeAutoObservable(this)
  }
  setShowMobileMenu = (s) => {
    this.showMobileMenu = s
  }
  setViewIndex = (viewIndex) => {
    this.viewIndex = viewIndex
  }
  setBackgroundColor = (color) => {
    this.backgroundColor = color
  }
  setCentraCategoryUri = (uri) => {
    this.centraCategoryUri = uri
  }

  setCustom = (c) => {
    this.custom = c
  }
}

export const sideMenuStore = (() => {
  return new SideMenuStore()
})()

export const SideMenuStoreContext = React.createContext(sideMenuStore)
