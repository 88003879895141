import {
  googleAddToCart,
  googleRemoveFromCart,
  googlePurchase,
  googleBeginCheckout,
  googleViewCart,
  googleViewProduct,
  googleViewCategory,
  googleSelectItemInCategory,
} from './google'
import {
  fbAddToCart,
  fbPurchase,
  fbBeginCheckout,
  fbViewContent,
} from './facebook'
import { findifyUpdateCart, findifyPurchase, findifyViewPage } from './findify'
import { yotpoTriggerPurchase } from './yotpo'
import { captureException } from '../../helpers/captureException'
import { getProductTrackingId } from '../../helpers/getProductTrackingId'
import { toJS } from 'mobx'

export const triggerPageView = ({}) => {
  try {
    findifyViewPage({})
  } catch (error) {
    captureException(error)
  }
}

export const triggerPurchase = ({ order, locale, CSObject, settingsData }) => {
  try {
    if (!order) return
    const orderData = getOrderData({ order, CSObject, locale })

    googlePurchase({ orderData })
    fbPurchase({ orderData }).catch((error) => {
      captureException(error)
    })
    findifyPurchase({ orderData })
    if (settingsData?.yotpo_app_key || true) {
      yotpoTriggerPurchase({ orderData, locale }).catch((error) => {
        captureException(error)
      })
    }
  } catch (error) {
    captureException(error)
  }
}

export const triggerAddToCart = ({ item, CSObject }) => {
  try {
    const itemData = getItemData({ item, CSObject })
    googleAddToCart({ itemData })
    fbAddToCart({ itemData })

    const orderData = getOrderData({ order: CSObject?.selection, CSObject })
    findifyUpdateCart({ orderData })
  } catch (error) {
    captureException(error)
  }
}

export const triggerRemoveFromCart = ({ item, CSObject }) => {
  try {
    const itemData = getItemData({ item, CSObject })
    googleRemoveFromCart({ itemData })

    const orderData = getOrderData({ order: CSObject?.selection, CSObject })
    findifyUpdateCart({ orderData })
  } catch (error) {
    captureException(error)
  }
}

export const triggerViewCart = ({ CSObject }) => {
  try {
    const orderData = getOrderData({ order: CSObject?.selection, CSObject })

    googleViewCart({ orderData })
  } catch (error) {
    captureException(error)
  }
}

export const triggerSelectItem = ({
  item,
  itemListId,
  itemListName,
  CSObject,
}) => {
  try {
    const itemData = getItemData({ item, CSObject })
    googleSelectItemInCategory({ itemData, itemListId, itemListName })
  } catch (error) {
    captureException(error)
  }
}
export const triggerViewItemList = ({
  items,
  itemListId,
  itemListName,
  CSObject,
}) => {
  try {
    const itemsData = items.map((item) => getItemData({ item, CSObject }))
    googleViewCategory({ itemsData, itemListId, itemListName })
  } catch (error) {
    captureException(error)
  }
}

export const triggerBeginCheckout = ({ CSObject }) => {
  try {
    const orderData = getOrderData({ order: CSObject?.selection, CSObject })

    googleBeginCheckout({ orderData })
    fbBeginCheckout({ orderData })
  } catch (error) {
    captureException(error)
  }
}

export const triggerViewProduct = ({ item, CSObject }) => {
  try {
    const itemData = getItemData({ item, CSObject })

    findifyViewPage({ itemData })
    googleViewProduct({ itemData })
    fbViewContent({ itemData })
  } catch (error) {
    captureException(error)
  }
}

const getItemData = ({ item, CSObject }) => {
  if (process.env.NEXT_PUBLIC_DEBUG_TRACKING === 'true')
    console.log('getItemData', toJS(item))
  if (!item?.product) return false

  const id = getProductTrackingId({
    item,
    country: CSObject?.location?.country?.toLowerCase(),
  })
  const sku = item?.product?.sku
  const centraVariant = item?.product?.centraVariant
  const variantName = item?.product?.variantName
  const currency = CSObject?.selection?.currency
  const name = item?.product?.name
  const quantity = item?.quantity
  const brandName = item?.product?.brandName
  const categoryName = item?.product?.categoryName

  // Prices & discounts
  const taxPercent = item?.taxPercent
  const price =
    item?.priceEachWithoutTaxAsNumber ?? item?.product?.priceAsNumber //Especially for view product and add to cart events
  const priceIncTax = item?.priceAsNumber
  const totalPrice = removeVat({
    taxPercent,
    price: item?.totalPriceAsNumber,
  })
  const totalPriceIncTax = item?.totalPriceAsNumber
  const discount = removeVat({
    taxPercent,
    price: item?.priceEachReductionAsNumber,
  })
  const discountIncTax = item?.priceEachReductionAsNumber

  return {
    id,
    sku,
    centraVariant,
    variantName,
    currency,
    name,
    quantity,
    brandName,
    categoryName,
    taxPercent,
    price,
    priceIncTax,
    totalPrice,
    totalPriceIncTax,
    discount,
    discountIncTax,
  }
}

const getOrderData = ({ order, CSObject, locale }) => {
  if (process.env.NEXT_PUBLIC_DEBUG_TRACKING === 'true')
    console.log('getOrderData', toJS(order), locale)
  if (!order) return false

  const coupon = order?.discounts?.vouchers?.[0]?.voucher
  const currency = order?.currency
  const siteName = process.env.NEXT_PUBLIC_SITE_NAME
  const orderId = order?.order
  const items = order?.items?.map((item) => getItemData({ item, CSObject }))

  // Prices & discounts
  const taxPercent = order?.totals?.taxPercent
  const total =
    order?.totals?.grandTotalPriceAsNumber -
    order?.totals.grandTotalPriceTaxAsNumber
  const totalIncTax = order?.totals?.grandTotalPriceAsNumber
  const tax = order?.totals.grandTotalPriceTaxAsNumber
  const shippingIncTax = order?.totals.shippingPriceAsNumber
  const shipping = removeVat({
    taxPercent,
    price: shippingIncTax,
  })
  const totalDiscountIncTax = Math.abs(
    order?.totals?.totalDiscountPriceAsNumber,
  )
  const totalDiscount = removeVat({
    taxPercent,
    price: totalDiscountIncTax,
  })

  // User data
  const email = order?.address?.email
  const phoneNumber = order?.address?.phoneNumber
  const firstName = order?.address?.firstName
  const lastName = order?.address?.lastName
  const address1 = order?.address?.address1
  const address2 = order?.address?.address2
  const city = order?.address?.city
  const state = order?.address?.state
  const country = order?.address?.country
  const zipCode = order?.address?.zipCode

  return {
    locale,
    currency,
    siteName,
    orderId,
    total,
    totalIncTax,
    tax,
    taxPercent,
    shipping,
    shippingIncTax,
    totalDiscount,
    totalDiscountIncTax,
    coupon,
    items,
    email,
    phoneNumber,
    firstName,
    lastName,
    address1,
    address2,
    city,
    state,
    country,
    zipCode,
  }
}

const removeVat = ({ taxPercent, price }) => {
  if (!price) return 0

  price = price / ((100 + taxPercent) / 100)
  return Math.round(price * 100) / 100
}
