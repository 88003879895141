import React from 'react'
import { makeAutoObservable } from 'mobx'

class CompareStore {
  constructor() {
    this.ids = []
    makeAutoObservable(this)
  }
  addProduct = ({ id }) => {
    this.ids.push(id)
  }
  removeProduct = (id) => {
    this.ids = this.ids.filter((i) => i !== id)
  }
  remove = () => {
    this.ids = []
  }
}

export const compareStore = (() => {
  return new CompareStore()
})()

export const CompareStoreContext = React.createContext(compareStore)
