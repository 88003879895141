import React from 'react'
import { makeAutoObservable, toJS } from 'mobx'

class FilterStore {
  constructor() {
    this.filters = []
    this.sortOrder = {}
    makeAutoObservable(this)
  }

  addFilter = ({ field, value }) => {
    if (value && value.length > 0) {
      const filter = this.filters?.find((f) => f.field === field)
      if (filter) {
        filter.values.push(value)
        // Remove Duplicates and empty
        filter.values.filter(
          (item, index) => filter.values.indexOf(item) === index,
        )
      } else {
        this.filters.push({ field: field, values: [value] })
      }
    }
  }

  removeFilter = ({ field, value }) => {
    const filter = this.filters?.find((f) => f.field === field)
    if (filter) {
      filter.values = filter.values.filter((v) => v !== value)
      if (filter.values.length === 0) {
        this.filters = this.filters.filter((f) => f.field !== field)
      }
    }
  }

  isFilterSelected = ({ filter, value }) => {
    const f = this.filters
      ?.find((f) => f.field === filter.field)
      ?.values.find((v) => v === value.value)
    if (f) {
      return true
    }

    return false
  }

  setSortOrder = (so) => {
    this.sortOrder = so
  }
}

export const filterStore = (() => {
  return new FilterStore()
})()

export const FilterStoreContext = React.createContext(filterStore)
