import { isServer } from '../../helpers/isServer'

export const googlePurchase = ({ orderData }) => {
  // Google dataLayer
  const coupon = orderData.coupon
  const data = {
    transaction_id: orderData.orderId,
    affiliation: orderData.siteName,
    value: orderData.total,
    currency: orderData.currency,
    tax: orderData.tax,
    shipping: orderData.shipping,
    items: getProducts({ items: orderData.items }),
    // Custom fields
    total_discount: orderData.totalDiscount,
  }
  if (coupon) {
    data['coupon'] = coupon
  }

  const userData = {
    email: orderData.email,
    phone: orderData.phoneNumber,
    first_name: orderData.firstName,
    last_name: orderData.lastName,
    street: orderData.address1,
    city: orderData.city,
    region: orderData.state,
    country: orderData.country,
    postcode: orderData.zipCode,
  }

  dataLayerPush({ event: 'purchase', data, userData }) // Trigger event
}

export const googleBeginCheckout = ({ orderData }) => {
  let data = {
    currency: orderData.currency,
    value: orderData.total,
    items: orderData.items.map((itemData) => {
      return getProduct({ itemData })
    }),
  }

  dataLayerPush({ event: 'begin_checkout', data })
}

export const googleViewCart = ({ orderData }) => {
  let data = {
    currency: orderData.currency,
    value: orderData.total,
    items: orderData.items.map((itemData) => {
      return getProduct({ itemData })
    }),
  }

  dataLayerPush({ event: 'view_cart', data })
}

export const googleAddToCart = ({ itemData }) => {
  let data = {
    currency: itemData.currency,
    value: itemData.price,
    items: [getProduct({ itemData })],
  }
  dataLayerPush({ event: 'add_to_cart', data })
}

export const googleRemoveFromCart = ({ itemData }) => {
  const data = {
    currency: itemData.currency,
    value: itemData.price,
    items: [getProduct({ itemData })],
  }

  dataLayerPush({ event: 'remove_from_cart', data })
}

export const googleViewProduct = ({ itemData }) => {
  const data = {
    currency: itemData.currency,
    value: itemData.price,
    items: [getProduct({ itemData })],
  }
  dataLayerPush({ event: 'view_item', data })
}

export const googleViewCategory = ({ itemsData, itemListId, itemListName }) => {
  const data = {
    item_list_id: itemListId,
    item_list_name: itemListName,
    items: itemsData.map((itemData) => getProduct({ itemData })),
  }
  dataLayerPush({ event: 'view_item_list', data })
}

export const googleSelectItemInCategory = ({
  itemData,
  itemListId,
  itemListName,
}) => {
  const data = {
    item_list_id: itemListId,
    item_list_name: itemListName,
    items: [getProduct({ itemData })],
  }

  dataLayerPush({ event: 'select_item', data })
}

const dataLayerPush = ({ event, data, userData }) => {
  if (isServer) return

  if (window.dataLayer) {
    let datalayerData = {
      event: event,
      ecommerce: data,
    }
    if (userData) {
      datalayerData['user_data'] = userData
    }
    if (process.env.NEXT_PUBLIC_DEBUG_TRACKING === 'true') {
      console.log('dataLayer preview', datalayerData)
    }

    dataLayer.push({ ecommerce: null }) // Best practice to clear first
    dataLayer.push(datalayerData)
  } else {
    console.log('no dataLayer found')
  }
}

const getProducts = ({ items }) => {
  return items.map((itemData) => {
    return getProduct({ itemData })
  })
}

const getProduct = ({ itemData }) => {
  let data = {
    item_id: itemData.id,
    item_name: itemData.name,
    item_brand: itemData.brandName,
    item_variant: itemData.variantName,
    quantity: itemData.quantity ?? 1,
    price: itemData.price ?? 0,
    discount: itemData.discount ?? 0,
    // Custom fields
    centra_sku: itemData?.sku ?? '',
    centra_tax_percent: itemData?.taxPercent ?? 0,
  }
  if (itemData.coupon) {
    data['coupon'] = itemData.coupon
  }

  itemData.categoryName.map((categoryName, index) => {
    index = index == 0 ? '' : index + 1
    data[`item_category${index}`] = categoryName
  })

  return data
}
