import { isServer } from '../../helpers/isServer'
import { toJS } from 'mobx'

export const GET = async ({ headers, url }) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...(process.env.CENTRA_SECRET && {
        'API-Authorization': process.env.CENTRA_SECRET,
      }),
      ...(headers ? headers : {}),
    },
  })
  if (response.status !== 500) {
    const json = await response.json()
    if (json.token) {
      setCentraToken(json.token)
    }
    return { json, status: response.status }
  } else {
    throw new Error('Something went wrong')
  }
}
export const POST = async ({
  headers,
  url,
  body,
  dontSaveCentraToken = false,
}) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(process.env.CENTRA_SECRET && {
        'API-Authorization': process.env.CENTRA_SECRET,
      }),
      ...(headers ? headers : {}),
    },
    body: JSON.stringify(body),
  })

  if (response.status !== 500) {
    const json = await response.json()
    if (json.token && !dontSaveCentraToken) {
      setCentraToken(json.token)
    }
    return { json, status: response.status }
  } else {
    throw new Error('Something went wrong')
  }
}

export const PUT = async ({ headers, url, body }) => {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...(process.env.CENTRA_SECRET && {
        'API-Authorization': process.env.CENTRA_SECRET,
      }),
      ...(headers ? headers : {}),
    },
    body: JSON.stringify(body),
  })
  if (response.status !== 500) {
    const json = await response.json()
    if (json.token) {
      setCentraToken(json.token)
    }
    return { json, status: response.status }
  } else {
    throw new Error('Something went wrong')
  }
}

export const DELETE = async ({ headers, url, body }) => {
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...(process.env.CENTRA_SECRET && {
        'API-Authorization': process.env.CENTRA_SECRET,
      }),
      ...(headers ? headers : {}),
    },
    body: JSON.stringify(body),
  })
  if (response.status !== 500) {
    const json = await response.json()
    if (json.token) {
      setCentraToken(json.token)
    }
    return { json, status: response.status }
  } else {
    throw new Error('Something went wrong')
  }
}

const setCentraToken = (token) => {
  if (!isServer) {
    const savedToken = getCentraToken()
    if (!savedToken || token !== savedToken) {
      window.localStorage.setItem(
        process.env.NEXT_PUBLIC_SITE_NAME + '_centra_token',
        token,
      )
    }
  }
}
const getCentraToken = () => {
  if (!isServer) {
    return {
      'API-Token': window.localStorage.getItem(
        process.env.NEXT_PUBLIC_SITE_NAME + '_centra_token',
      ),
    }
  }
}

export const getCategories = async ({
  locale,
  pricelist,
  market,
  dontSaveCentraToken,
}) => {
  const response = await POST({
    dontSaveCentraToken,
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/categories`,
    body: {
      language: locale ?? '',
      pricelist: pricelist ?? '',
      market: market ?? '',
    },
  })

  return response.json.categories ?? []
}

export const getLanguages = async () => {
  const response = await GET({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/languages`,
  })

  return response.json.languages
}

export const getProducts = async ({
  locale,
  pricelist,
  market,
  search,
  categories,
  skip,
  limit = process.env.NEXT_PUBLIC_CENTRA_PRODUCT_LIMIT,
  filters,
  sortOrder,
}) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/products`,
    headers: { ...getCentraToken() },
    body: {
      language: locale ?? '',
      pricelist: pricelist ?? '',
      market: market ?? '',
      search: search,
      categories: categories,
      limit: limit,
      skipFirst: skip,
      relatedProducts: true,
      ...(filters ? filters : {}),
      ...(sortOrder ? { sortOrder: [sortOrder] } : {}),
    },
  })
  return response.json
}

export const getProductFromUri = async ({ uri, locale, pricelist, market }) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/products`,
    headers: { ...getCentraToken() },
    body: {
      uri: {
        uri: uri,
        for: ['product'],
      },
      language: locale ?? '',
      pricelist: pricelist ?? '',
      market: market ?? '',
      relatedProducts: true,
    },
  })

  return response?.json?.products?.length > 0
    ? response?.json?.products[0]
    : null
}

export const getProductsFromCategoryUri = async ({
  uri,
  locale,
  pricelist,
  market,
  skip,
}) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/products`,
    headers: { ...getCentraToken() },
    body: {
      uri: {
        uri: uri,
        for: ['category'],
      },
      language: locale ?? '',
      pricelist: pricelist ?? '',
      market: market ?? '',
      limit: process.env.NEXT_PUBLIC_CENTRA_PRODUCT_LIMIT,
      skipFirst: skip,
      relatedProducts: true,
    },
  })

  return response.json
}
export const getMarkets = async () => {
  const response = await GET({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/markets`,
  })

  return response.json
}

export const getCategoryFromUri = async ({
  uri,
  locale,
  pricelist,
  market,
  skip,
}) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/uri`,
    headers: { ...getCentraToken() },
    body: {
      uri: uri,
      language: locale ?? '',
      pricelist: pricelist ?? '',
      market: market ?? '',
      limit: process.env.NEXT_PUBLIC_CENTRA_PRODUCT_LIMIT,
      skipFirst: skip,
      for: ['category'],
    },
  })

  return response.json
}

export const getBricKAndMortar = async () => {
  const response = await GET({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/brick-and-mortar`,
  })

  return response.json
}

export const getCountries = async () => {
  const response = await GET({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/countries`,
  })

  return response.json
}

//**CLIENT STUFF =>==>>>>>**//

export const getCurrentCategoryFromUri = async ({ uri, locale }) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/uri`,
    headers: { ...getCentraToken() },
    body: {
      uri: uri,
      language: locale ?? '',
      for: ['category'],
    },
  })

  return response?.json?.category ? response?.json?.category : null
}

export const getCurrentProductFromUri = async ({ uri, locale }) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/uri`,
    headers: { ...getCentraToken() },
    body: {
      uri: uri,
      language: locale ?? '',
      for: ['product'],
    },
  })

  return response?.json?.product ? response?.json?.product : null
}

export const getCentraSessionObject = async () => {
  const response = await GET({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/selection`,
    headers: { ...getCentraToken() },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const getProduct = async ({ id, locale }) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/products/${id}`,
    headers: { ...getCentraToken() },
    body: { language: locale ?? '' },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const getProductWithCentraProductId = async ({ id, locale }) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/products`,
    headers: { ...getCentraToken() },
    body: { centraProduct: id },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const addToSelection = async ({ id, body }) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/items/${id}`,
    headers: { ...getCentraToken() },
    body: body,
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const removeFromSelection = async ({ id }) => {
  const response = await PUT({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/lines/${id}/quantity/0`,
    headers: { ...getCentraToken() },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const updateItemsSubscription = async ({ lineId, subscriptionId }) => {
  const response = await PUT({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/lines/${lineId}/subscription-plan/${subscriptionId}`,
    headers: { ...getCentraToken() },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const setQuantityForSelection = async ({ lineId, quantity }) => {
  const response = await PUT({
    url:
      `${process.env.NEXT_PUBLIC_CENTRA_URL}/lines/${lineId}/quantity/` +
      quantity,
    headers: { ...getCentraToken() },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const changeCountry = async ({ country, locale }) => {
  try {
    const response = await PUT({
      url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/countries/` + country,
      // TODO. Get 500 error if items in selection and change country / lang
      headers: { ...getCentraToken() },
      body: {
        language: locale,
      },
    })

    if (response.json.errors) {
      throw response.json.errors
    }
    return response.json
  } catch (e) {
    throw e
  }
}
export const changeLanguage = async ({ locale }) => {
  try {
    const response = await PUT({
      url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/languages/` + locale,
      headers: { ...getCentraToken() },
    })

    if (response.json.errors) {
      throw response.json.errors
    }
    return response.json
  } catch (e) {
    throw e
  }
}

export const addVoucherToSelection = async ({ code }) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/vouchers`,
    headers: { ...getCentraToken() },
    body: { voucher: code },
  })

  if (response.json.errors) {
    throw { errors: response.json.errors, status: response.status }
  }
  return response.json
}

export const newLetterSubscription = async ({
  email,
  country,
  language,
  item,
  product,
}) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/newsletter-subscription`,
    headers: { ...getCentraToken() },
    body: { email, country, language, item: item, product: product },
  })

  if (response.errors) {
    throw response.errors
  }
  return response
}

export const removeVoucherToSelection = async ({ code }) => {
  const response = await DELETE({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/vouchers`,
    headers: { ...getCentraToken() },
    body: { voucher: code },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}
export const startPayment = async ({
  country,
  paymentMethod,
  language,
  shippingMethod,
  CSObject,
}) => {
  const token = getCentraToken()
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/payment`,
    headers: { ...token },
    body: {
      paymentMethod: paymentMethod,
      shippingMethod: shippingMethod,
      paymentReturnPage:
        process.env.NEXT_PUBLIC_SITE_URL +
        '/' +
        language +
        '/receipt?vt=' +
        token['API-Token'],
      paymentFailedPage:
        process.env.NEXT_PUBLIC_SITE_URL + '/' + language + '/payment-error',
      termsAndConditions: true,
      address: {
        newsletter: false,
        country: country,
      },
      // shippingAddress: {
      //   country: country,
      // },
    },
  })

  if (response.json.errors) {
    if (response.json.unavailable) {
      throw response.json
    }
    throw response.json.errors
  }
  return response.json
}

export const paymentResult = async ({ query, token }) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/payment-result`,
    headers: { 'API-Token': token },
    body: {
      paymentMethodFields: {
        ...query,
        // centraPaymentMethod: 'klarnacheckout',
        // klarna_order: klarnaOrder,
      },
    },
  })

  if (response.json.errors) {
    throw response
  }
  return response.json
}
export const paymentFields = async ({ data }) => {
  const response = await PUT({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/payment-fields`,
    headers: { ...getCentraToken() },
    body: { ...data },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const centraGetReceipt = async () => {
  const response = await GET({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/receipt`,
    headers: { ...getCentraToken() },
  })

  if (response.json.errors) {
    throw response.json.errors
  }

  return response.json
}

export const setStatusOnSubscription = async ({ status, subscriptionId }) => {
  const response = await PUT({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/subscription/status`,
    headers: { ...getCentraToken() },
    body: { status, subscription: subscriptionId },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const registerUser = async ({
  email,
  password,
  firstName,
  lastName,
}) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/register`,
    headers: { ...getCentraToken() },
    body: { email, password, firstName, lastName },
  })

  if (response.json.errors) {
    throw { errors: response.json.errors, status: response.status }
  }
  return response.json
}

export const logoutUser = async () => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/logout`,
    headers: { ...getCentraToken() },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const loginUser = async ({ email, password }) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/login/${email}`,
    headers: { ...getCentraToken() },
    body: { password },
  })

  if (response.json.errors) {
    throw { errors: response.json.errors, status: response.status }
  }
  return response.json
}

export const sendResetPasswordEmail = async ({ email }) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/password-reset-email`,
    headers: { ...getCentraToken() },
    body: { email, linkUri: 'my-page/reset-password' },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const resetPassword = async ({ password, id, i }) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/password-reset`,
    headers: { ...getCentraToken() },
    body: { newPassword: password, id, i },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const updateCustomer = async ({
  firstName,
  lastName,
  address1,
  zipCode,
  city,
  state,
  country,
  phoneNumber,
}) => {
  const response = await PUT({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/customer/update`,
    headers: { ...getCentraToken() },
    body: {
      firstName,
      lastName,
      address1,
      zipCode,
      city,
      state,
      country,
      phoneNumber,
    },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const getOrders = async ({ from, size }) => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/orders`,
    headers: { ...getCentraToken() },
    body: { from, size },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const getSubscriptions = async () => {
  const response = await POST({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/customer/subscriptions`,
    headers: { ...getCentraToken() },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}

export const updateCustomerEmail = async ({ email }) => {
  const response = await PUT({
    url: `${process.env.NEXT_PUBLIC_CENTRA_URL}/email`,
    headers: { ...getCentraToken() },
    body: { newEmail: email },
  })

  if (response.json.errors) {
    throw response.json.errors
  }
  return response.json
}
